@tailwind base;
@tailwind components;
@tailwind utilities;


/* Track */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-4px);
  }
  20% {
    transform: translateX(4px);
  }
  30% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  60% {
    transform: translateX(0);
  }
}

@layer utilities {
  .animate-shake {
    animation: shake 0.5s;
  }
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: transparent; /* Transparent background */
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #333; /* Light grey background */
}

/* Styles for the scrollbar handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Dark grey scrollbar handle */
  border-radius: 4px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Slightly darker grey on hover */
}

/* Styles for the scrollbar itself (width, height) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer components {
  .button {
    @apply bg-gradient-to-l from-[#3A007E] to-[#21004A] py-2 px-4 rounded-full text-white text-lg font-semibold hover:opacity-80 duration-200 ease-in-out
  }
}
@layer components {
  .active-button{
    @apply bg-[#3A007E] py-2 px-4 rounded-full text-white text-lg font-semibold hover:opacity-80 duration-300 scale-110 ease-in-out
  }
}
@keyframes carAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.dropdown{
  background-color: #00000080;
}

.filter-white {
  filter: invert(100%) sepia(96%) saturate(16%) hue-rotate(350deg)
    brightness(104%) contrast(100%);
}

.select-status{
  background-image: url("./assets/icon-chevron-down.svg");
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
}

.react-calendar {
  width: 300px;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 25%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 22px;
  margin-bottom: .5em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}




.react-datetime-picker {
  display: inline-flex;
  position: relative;
}

.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-datetime-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
}

.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-datetime-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #0078d7;
}

.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}

.react-datetime-picker__button svg {
  display: inherit;
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  z-index: 1;
}

.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
  display: none;
}

.react-datetime-picker__calendar {
  width: 350px;
  max-width: 100vw;
}

.react-datetime-picker__calendar .react-calendar {
  border-width: thin;
}

.react-datetime-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
}
